import { useEffect } from 'react';
import { usePermissions, useRedirect } from 'react-admin';
import { hasPermission } from '../authProvider';
import { ROLE_ADMIN, ROLE_EDIT, ROLE_SALES } from '../common_modules/constants/define';
import { Box } from '@mui/material';
import { getLocalStorageId } from '../authProvider'; // getLocalStorageIdをインポート

export const Dashboard = () => {
  const { isLoading, permissions } = usePermissions();
  const redirect = useRedirect();
  const userId = getLocalStorageId();

  useEffect(() => {
    if (!isLoading && userId) {
      if (hasPermission(permissions, ROLE_EDIT)) {
        redirect(`/invite-url/${userId}/show`);
        return;
      }
      if (hasPermission(permissions, ROLE_SALES)) {
        redirect(`/invite-url/${userId}/show`);
        return;
      }
      if (hasPermission(permissions, ROLE_ADMIN)) {
        redirect(`/invite-url/${userId}/show`);
        return;
      }
    }
  }, [isLoading, permissions, userId, redirect]);

  return <Box />;
};

export default Dashboard;
