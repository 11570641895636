import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import baseTheme, { baseResponsiveFontSizes } from './common_modules/theme/BaseTheme';

let theme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#78605C'
    },
    secondary: {
      main: '#C59F98',
      contrastText: '#ffffff'
    }
  }
});
theme = baseResponsiveFontSizes(theme);

export default theme;
