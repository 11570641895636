import React from 'react';
import { TextField, TextFieldProps, useRecordContext } from 'react-admin';

const formatPostalCode = (value: string): any => {
  return `〒${value.slice(0, 3)}-${value.slice(3)}`;
};

interface CustomTextFieldProps extends TextFieldProps {
  format?: (value: string) => string;
}

const PostalCodeField: React.FC<CustomTextFieldProps> = ({ source, ...props }) => {
  const record = useRecordContext();
  if (!record || !source || !record[source]) {
    return null;
  }

  const value = record[source];
  const formattedValue = formatPostalCode(value);

  return <TextField source={source} {...props} record={{ ...record, [source]: formattedValue }} />;
};

export default PostalCodeField;
