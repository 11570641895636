import { useEffect } from 'react';
import { useRedirect, Show, SimpleShowLayout, ImageField, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import CustomUrlField from '../common_modules/components/CustomUrlField';
import { getLocalStorageId } from '../authProvider';

const NoActions = () => null;

export const InviteUrlShow = () => {
  const translate = useTranslate();
  const recordId = getLocalStorageId();
  const redirect = useRedirect();

  useEffect(() => {
    if (!recordId) {
      redirect('/login');
    }
  }, [recordId, redirect]);

  if (!recordId) {
    return null;
  }

  return (
    <Show
      resource="settings"
      id={recordId} // ここで recordId は string 型として渡されることが保証される
      title={translate('resources.settings.fields.inviteCodeUrl')}
      actions={<NoActions />}
    >
      <SimpleShowLayout sx={{ mt: 2 }}>
        <Typography variant="body1">以下のURL、QRコードを使用して新規パートナーを招待してください。</Typography>
        <CustomUrlField source="inviteCodeUrl" />
        <ImageField source="qrCode" label="QRコード" sx={{ mt: 2 }} />
      </SimpleShowLayout>
    </Show>
  );
};
