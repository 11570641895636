import {
  Edit,
  SelectInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useTranslate,
  ImageInput,
  ImageField
} from 'react-admin';
import { BANK_ACCOUNT_TYPE, EC_SITE_TYPE, PREFECTURE_LIST } from '../common_modules/constants/choices';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import { Typography, Box } from '@mui/material';
import CustomUrlField from '../common_modules/components/CustomUrlField';
import EnumTextField from '../common_modules/components/EnumTextField';
import PaymentDateTextField from '../common_modules/components/PaymentDateTextField';
import FormValidation from '../common_modules/components/FormValidation'; // 追加: FormValidation のインポート
import PercentNumberField from '../common_modules/components/PercentNumberField';
import PercentNumberInput from '../common_modules/components/PercentNumberInput';

// カスタムツールバーを定義して削除ボタンを表示しないようにする
const CustomToolbar = ({ isFormValid }: { isFormValid: boolean }) => (
  <Toolbar style={{ backgroundColor: '#FFFFFF' }}>
    <SaveButton disabled={!isFormValid} /> {/* isFormValid の状態に応じてボタンを無効化 */}
  </Toolbar>
);

export const SettingEdit = () => {
  const translate = useTranslate();
  const handleError = useErrorHandler();

  // 必須フィールドの定義
  const requiredFields = [
    'name',
    'email',
    'tel',
    'zipCode',
    'prefecture',
    'city',
    'address1',
    'ecSiteUrl',
    'ecSiteType',
    'defaultPartnerReward'
  ];

  return (
    <Edit
      title={translate('resources.settings.name')}
      redirect={false}
      mutationMode="pessimistic"
      mutationOptions={{ onError: handleError }}
    >
      <SimpleForm
        toolbar={
          <FormValidation requiredFields={requiredFields}>
            {(isFormValid) => <CustomToolbar isFormValid={isFormValid} />}
          </FormValidation>
        }
        sx={{ maxWidth: '600px' }}
      >
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="email" validate={required()} fullWidth />
        <TextInput source="tel" validate={required()} />
        <TextInput source="zipCode" validate={required()} />
        <SelectInput source="prefecture" validate={required()} choices={PREFECTURE_LIST} />
        <TextInput source="city" validate={required()} fullWidth />
        <TextInput source="address1" validate={required()} fullWidth />
        <TextInput source="address2" fullWidth />
        <TextInput source="ecSiteUrl" validate={required()} fullWidth />
        <NumericRadioButtonGroupInput source="ecSiteType" validate={required()} choices={EC_SITE_TYPE} />
        <Box sx={{ mb: 4 }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {translate('resources.settings.fields.closingDate')}
          </Typography>
          <PaymentDateTextField source="closingDate" label="EEEE" />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {translate('resources.settings.fields.invoiceDate')}
          </Typography>
          <PaymentDateTextField source="invoiceDate" />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {translate('resources.settings.fields.plannedPaymentMonth')}
          </Typography>
          <EnumTextField source="plannedPaymentMonth" translationKeyPrefix="values.paymentMonth" />
        </Box>
        <PercentNumberInput source="defaultPartnerReward" validate={required()} />
        <Box sx={{ mb: 4 }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {translate('resources.settings.fields.plannedPaymentDate')}
          </Typography>
          <PaymentDateTextField source="plannedPaymentDate" />
        </Box>

        <Box sx={{ mb: 4 }}>
          <CustomUrlField source="inviteCodeUrl" label={'パートナー招待URL'} />
          <ImageField source="qrCode" sx={{ mt: 2 }} />
        </Box>

        <Box display="flex" justifyContent="flex-start">
          <ImageInput source="storeLogoThumbnail" label="ストア画像" accept="image/png">
            <ImageField source="src" title="ストア画像" />
          </ImageInput>
        </Box>
        <Typography variant="body2" color="textSecondary">
          ストア画像はPNG形式で、ファイルサイズは最大500kBまでです。
          <br />
          幅180px、高さ150px以下の画像をアップロードしてください。
        </Typography>

        <Box display="flex" justifyContent="flex-start">
          <ImageInput source="bannerImage" label="バナー画像" accept="image/png,image/jpeg">
            <ImageField source="src" title="バナー画像" />
          </ImageInput>
        </Box>
        <Typography variant="body2" color="textSecondary">
          バナー画像はPNG形式で、ファイルサイズは最大500kBまでです。
          <br />
          幅500px、高さ500px以下の画像をアップロードしてください。
        </Typography>
      </SimpleForm>
    </Edit>
  );
};

export default SettingEdit;
