import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Container, Typography, Stack, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import dataProvider from '../dataProvider';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import SiteLogo from '../common_modules/components/SiteLogo';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Verification: React.FC = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const [hasError, setHasError] = useState(null);
  const [loading, setLoading] = useState(true);
  const requestSentRef = useRef(false);
  useEffect(() => {
    setLoading(true);
    let ignore = false;
    async function fetchToken() {
      try {
        if (requestSentRef.current) return;
        requestSentRef.current = true;

        if (!ignore) {
          const token = new URLSearchParams(location.search).get('t');
          const requestBody = {
            token
          };
          await dataProvider.register(requestBody, '/registers/clients/verification');
        }
      } catch (e: any) {
        // TODO: 現在本番も2回そうしんされる問題があるため暫定
        if (!ignore) {
          if (e.message == 'すでに登録済みです。') {
            setHasError(e.message);
          }
        }
      }

      setLoading(false);
    }

    fetchToken();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <RegisterLayout>
      <Container>
        <Helmet>
          <title>{translate('resources.registers.name.Verification')}</title>
        </Helmet>
        <Stack spacing={1}>
          {/* <RegisterHeaderProgress
            title={'resources.registers.name.Completion'}
            icon={<CheckCircleOutlineIcon />}
            step={7}
            totalSteps={7}
          /> */}
          <SiteLogo />
          {!loading && (
            <>
              {!hasError ? (
                <>
                  <Typography variant={'h4'}>審査</Typography>
                  <Typography>
                    申請者の本人確認が完了しました。
                    <br />
                    ECサイト登録のための審査を行います。この審査は通常3~5営業日で完了します。
                    <br />
                    審査終了後はご登録のメールアドレスに審査結果のメールが送られます。
                    <br />
                    <br />
                    7営業日を超えても審査結果及び運営からの連絡がない場合は、お手数ではございますが、FEEBACKのサポートへお問い合わせください。
                    <br />
                    support@feeback.com
                  </Typography>
                </>
              ) : (
                <>
                  <Box display="flex" alignItems="center" sx={{ padding: 2 }}>
                    <ErrorOutlineIcon sx={{ marginRight: 1 }} />
                    <Typography variant="h5" align="left">
                      {hasError}
                    </Typography>
                  </Box>
                </>
              )}
            </>
          )}
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default Verification;
