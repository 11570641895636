import { AuthProvider } from 'react-admin';
import { getMeData } from './dataProvider';
import { Authority, ROLE_ADMIN, ROLE_EDIT, ROLE_SALES } from './common_modules/constants/define';
import AuthHelper from './common_modules/utils/AuthHelper';

/**
 * This authProvider is only for test purposes. Don't use it in production.
 */
export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const authResult = await AuthHelper.login(username, password);
      const result = authResult.cognitoUserSession;
      if (result) {
        localStorage.setItem('token', result.getAccessToken().getJwtToken());

        // get member info
        const me = await getMeData();
        localStorage.setItem('name', me.name);
        localStorage.setItem('clientId', me.clientId);
        localStorage.setItem('authority', me.authority);

        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout: () => {
    removeLocalStorage();
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      removeLocalStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return getLocalStorageToken() ? Promise.resolve() : Promise.reject({ message: false });
  },
  getPermissions: () => {
    const authority = parseInt(localStorage.getItem('authority')!, 10);
    let ret: string[] = [];
    if (authority === Authority.Admin) {
      ret = [ROLE_ADMIN, ROLE_EDIT, ROLE_SALES];
    } else if (authority === Authority.Edit) {
      ret = [ROLE_EDIT, ROLE_SALES];
    } else if (authority === Authority.Sales) {
      ret = [ROLE_SALES];
    }
    return Promise.resolve(ret);
  },
  getIdentity: () => {
    const persistedUser = getLocalStorageToken() ?? '';

    return Promise.resolve({
      id: persistedUser,
      fullName: localStorage.getItem('name') ?? undefined
    });
  },
  isAuthPage: (pathname: string): boolean => {
    if (pathname === '/register') {
      return false;
    }
    return true;
  }
};

const removeLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('name');
  localStorage.removeItem('clientId');
  localStorage.removeItem('authority');
};

export const getLocalStorageId = () => {
  return localStorage.getItem('clientId');
};

export const getLocalStorageToken = () => {
  return localStorage.getItem('token');
};

export const hasPermission = (permissions: string[] | null, requiredPermission: string): boolean => {
  if (permissions) {
    return permissions.includes(requiredPermission);
  }
  return false;
};

export default authProvider;
