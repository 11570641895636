import React from 'react';
import { TextField, useTranslate, FieldProps, useRecordContext } from 'react-admin';

interface PaymentDateTextFieldProps extends FieldProps {
  source: string;
}

const PaymentDateTextField: React.FC<PaymentDateTextFieldProps> = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const translate = useTranslate();
  
  if (!record) return null;

  const value = record[source];
  let translatedText;
  if (value === 99) {
    translatedText = translate(`values.paymentDateEndOfMonth`);
  } else {
    translatedText = translate(`values.paymentDate`, {day: value});
  }
  return (
    <TextField {...props} source={source} record={{ ...record, [source]: translatedText }} />
  );
};

export default PaymentDateTextField;
