import { Edit, useTranslate, TextInput, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import { Typography } from '@mui/material';
import { getLocalStorageId } from '../authProvider';

const NoActions = () => null;
const CustomToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const ClientUrlEdit = () => {
  const translate = useTranslate();
  const recordId = getLocalStorageId();
  console.log(recordId);
  if (!recordId) {
    return <div>ECサイトURLが取得できませんでした。</div>;
  }

  return (
    <Edit
      resource="settings"
      id={recordId}
      title={translate('resources.settings.fields.ecSiteUrl')}
      actions={<NoActions />}
      redirect={false}
    >
      <SimpleForm sx={{ mt: 2 }} toolbar={<CustomToolbar />}>
        <Typography variant="body1">
          以下にECサイトのURLを入力してください。
          <br />
          変更するとアフィリエイトURLにも反映されます。
        </Typography>
        <TextInput source="ecSiteUrl" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
