import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

interface AspectRatioBoxProps {
  children: ReactNode;
  ratio?: number;
}

const AspectRatioBox: React.FC<AspectRatioBoxProps> = ({ children, ratio = 4 / 3 }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingBottom: `${(1 / ratio) * 100}%`, // 4:3 ratio
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AspectRatioBox;
