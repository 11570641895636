import {
  DateField,
  List,
  NumberField,
  SearchInput,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
  Exporter,
  TopToolbar,
  ExportButton,
  FilterButton,
  downloadCSV
} from 'react-admin';
import EnumTextField from '../common_modules/components/EnumTextField';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';
import { format, toZonedTime } from 'date-fns-tz';
import jsonExport from 'jsonexport/dist';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton label="CSVダウンロード" />
  </TopToolbar>
);

export const OrderList = () => {
  const translate = useTranslate();
  const queryClient = useQueryClient();

  useEffect(() => {
    // iOSのLoad failed対策として、ダウンロード画面表示中に再読み込みを行わないようにする
    queryClient.setQueryDefaults('orders', {
      refetchOnWindowFocus: false
    });
  }, [queryClient]);

  const exporter: Exporter = (records: any[]) => {
    if (!records || records.length === 0) {
      return;
    }

    const keys = Object.keys(records[0]).slice(2);
    const translatedHeaders = keys.map((key) => translate(`resources.orders.fields.${key}`, { _: key }));

    // データを再構築
    const dataToExport = records.map((record) => {
      const transformedRecord: Record<string, any> = {};
      keys.forEach((key, index) => {
        transformedRecord[translatedHeaders[index]] = record[key];
      });
      return transformedRecord;
    });

    // JSONをCSVに変換
    jsonExport(dataToExport, { headers: translatedHeaders }, (err: Error | null, csv: string) => {
      if (err) {
        console.error(err);
        return;
      }
      // CSVをダウンロード
      downloadCSV(csv, '注文');
    });
  };

  return (
    <List
      actions={<ListActions />}
      filters={[
        <SearchInput
          key="orderId"
          source="orderId"
          placeholder={translate('resources.orders.fields.orderId')}
          alwaysOn
        />,
        <SearchInput
          key="affiliateId"
          source="affiliateId"
          placeholder={translate('resources.orders.fields.affiliateId')}
        />,
        <SearchInput
          key="ecSiteClientId"
          source="ecSiteClientId"
          placeholder={translate('resources.orders.fields.ecSiteClientId')}
        />
      ]}
      exporter={exporter} // カスタムエクスポーターを指定
    >
      <FlexibleListLayout rowClick={false} bulkActionButtons={false}>
        <TextField source="orderId" label={translate('resources.orders.fields.orderId')} />
        <TextField source="ecSiteClientId" label={translate('resources.orders.fields.ecSiteClientId')} />
        <TextField source="affiliateId" label={translate('resources.orders.fields.affiliateId')} />
        <DateField showTime={true} source="orderDate" />
        <NumberField source="price" />
        <EnumTextField source="isCancelled" translationKeyPrefix="values.isCancelled" />
      </FlexibleListLayout>
    </List>
  );
};

export const OrderShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="orderId" />
      <TextField source="ecSiteClientId" />
      <TextField source="affiliateId" />
      <DateField showTime={true} source="orderDate" />
      <NumberField source="price" />
      <EnumTextField source="isCancelled" translationKeyPrefix="values.isCancelled" />
    </SimpleShowLayout>
  </Show>
);
